define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [["en-uk", {
    "alarm": {
      "details": {
        "faults": {
          "lockout": "Lockout Type",
          "reported": "Reported Time",
          "resolution": "Resolution"
        },
        "raisedAt": {
          "label": "Alarm Time",
          "tip": "{date}"
        },
        "resolvedAt": {
          "label": "Alarm Resolved Time",
          "tip": "{date}"
        },
        "severity": {
          "label": "Severity",
          "tip": "Severity of the alarm been raised"
        },
        "summary": {
          "label": "Alarm Details",
          "tip": "Details of the alarm been raised"
        },
        "type": {
          "label": "Alarm",
          "tip": "Type of alarm raised"
        }
      },
      "edit": {
        "crumb": "Edit",
        "messages": {
          "cancel": "Changes Reset",
          "server": "Server Error",
          "success": "Alarm Updated",
          "validation": "Validation Error"
        },
        "navTitle": "Edit",
        "title": "Edit Alarm"
      },
      "filter": {
        "clear": "Clear Filter",
        "severity": {
          "label": "Severity",
          "placeholder": "Alarm Severity",
          "tip": "Severity of the alarm been raised"
        },
        "status": {
          "label": "Connection Status",
          "placeholder": "Connection Status",
          "tip": "Device's connection status associated with an alarm"
        },
        "submit": "Update Filter",
        "title": "Filter Alarms"
      },
      "form": {
        "raisedAt": {
          "label": "Alarm Time",
          "tip": "When was the alarm been raised"
        },
        "severity": {
          "label": "Severity",
          "tip": "Severity of the alarm been raised"
        },
        "summary": {
          "label": "Alarm Details",
          "tip": "Details of the alarm been raised"
        },
        "type": {
          "label": "Alarm",
          "tip": "Type of alarm raised"
        }
      },
      "list": {
        "actions": {
          "filter": "Filter",
          "search": "Search",
          "sort": {
            "label": "Sort",
            "options": {
              "createdAt": "Created",
              "id": "ID",
              "updatedAt": "Updated"
            }
          }
        },
        "crumb": "Alarms",
        "empty": "No alarms found",
        "messages": {
          "export": "Export Started"
        },
        "resolvedCrumb": "Resolved Alarms",
        "showResolved": "Show resolved alarms",
        "showUnresolved": "Show unresolved alarms",
        "title": "List Alarms",
        "titleResolved": "List Resolved Alarms"
      },
      "nav": {
        "icon": "nav.users",
        "label": "Faults"
      },
      "new": {
        "crumb": "New",
        "messages": {
          "server": "Server Error",
          "success": "Alarm Created",
          "validation": "Validation Error"
        },
        "title": "New Alarm"
      },
      "resetLockout": {
        "buttonDescription": "Click the button below to start the process of resetting the appliance.",
        "confirmDescription": "Are you sure you want to reset the appliance?",
        "modalCancel": "Cancel",
        "modalConfirm": "Reset Appliance",
        "resetDescription": "Resetting an appliance will let the appliance attempt to start again if it's currently in fault.",
        "safetyDescription": "For safety, remote resets of the appliance are limited to 3 attempts.",
        "startReset": "Reset Appliance",
        "success": "Successfully reset the appliance.",
        "title": "Reset Appliance"
      },
      "severity": {
        "crit": "Critical",
        "info": "Info",
        "warn": "Warning"
      },
      "status": {
        "offline": "Offline",
        "online": "Online"
      },
      "telemetry": {
        "button": "Alarm Telemetry",
        "crumb": "Telemetry",
        "navTitle": "Telemetry",
        "title": "Alarm Telemetry",
        "titleResolved": "Resolved Alarm Telemetry"
      },
      "view": {
        "alarm-details": "Alarm Details",
        "crumb": "View",
        "deviceNavTitle": "Device",
        "navTitle": "View",
        "title": "View Alarm",
        "titleResolved": "View Resolved Alarm"
      }
    },
    "archive": {
      "confirm": "Confirm",
      "title": "Are you sure you want to {action} this",
      "titleShort": "Are you sure?"
    },
    "cognito": {
      "details": {
        "cognitoUpdatedAt": {
          "label": "Updated At"
        },
        "email": {
          "label": "Email"
        },
        "status": {
          "label": "Status"
        },
        "verified": {
          "label": "Verified",
          "tip": "Verified"
        }
      },
      "resend": {
        "button": "Resend Password",
        "server": "Server Error",
        "success": "Success"
      },
      "reset": {
        "button": "Reset Password",
        "server": "Server Error",
        "success": "Success"
      }
    },
    "dashboard": {
      "alarms": {
        "label": "Alarm",
        "severity": "Severity"
      },
      "awsStatus": {
        "cardTitle": "System Status",
        "degraded": {
          "description": "Users may see degraded performance in the apps and on the portal",
          "status": "Degraded"
        },
        "degradedService": "Degraded Service",
        "messagesTitle": "System Messages",
        "online": {
          "description": "All AWS services online",
          "message": "All AWS services are currently online and available",
          "status": "Online"
        },
        "outage": {
          "description": "Users may not be able to access the apps or the portal",
          "message": "We can`t connect to AWS, some or all services may be unavailable",
          "status": "Outage"
        },
        "title": "AWS Status"
      },
      "devices": {
        "serialNumber": "Logic Serial Number"
      },
      "loading": "Loading…",
      "refresh": "Refresh",
      "titles": {
        "alarmSummary": "Alarm Summary",
        "installationSummary": "Installation Summary",
        "recentAddDevices": "Recently added devices:",
        "recentAlarms": "Recently raised alarms:",
        "serviceDue": "Service Due"
      }
    },
    "delegated-partner": {
      "archive": {
        "body": "<p>This will archive the Delegation “<b>{name}</b>”.</p><p>This Delegat will no longer appear in the system. All data will be archived and require a manual process to restore.</p>",
        "button": "Archive Delegation",
        "crumb": "Archive",
        "messages": {
          "server": "Server Error",
          "success": "Delegation Archived"
        },
        "navTitle": "Archive",
        "title": "Archive Delegation"
      },
      "details": {
        "AddedAt": {
          "label": "Added at",
          "tip": "This is when the delegation was created"
        },
        "AddedByName": {
          "label": "Added by Name",
          "tip": "The is who created the delegation"
        },
        "delegatedBy": {
          "label": "Delegated By",
          "tip": "This the Group who has given access"
        },
        "delegatedTo": {
          "label": "Delegated To",
          "tip": "This the Group who has been given access"
        }
      },
      "edit": {
        "crumb": "Edit",
        "messages": {
          "cancel": "Changes Reset",
          "server": "Server Error",
          "success": "Delegation Updated",
          "validation": "Validation Error"
        },
        "navTitle": "Edit",
        "title": "Edit Delegation"
      },
      "filter": {
        "clear": "Clear Filter",
        "submit": "Filter Results"
      },
      "form": {
        "DelegatedBy": {
          "label": "Delegated By",
          "placeholder": "Enter Delegated By",
          "tip": "This is a tip for Delegated By",
          "title": "Access given by"
        },
        "delegatedTo": {
          "label": "Delegated By",
          "placeholder": "Enter Delegated By",
          "tip": "This is a tip for Delegated By",
          "title": "Giving access to"
        }
      },
      "list": {
        "actions": {
          "new": "Add New Delegation"
        },
        "crumb": "Delegations",
        "messages": {
          "export": "Export Started"
        },
        "title": "List Delegations"
      },
      "nav": {
        "icon": "nav.users",
        "label": "Delegations"
      },
      "new": {
        "crumb": "New",
        "messages": {
          "cancel": "Changes Reset",
          "server": "Server Error",
          "success": "Delegation Created",
          "validation": "Validation Error"
        },
        "title": "New Delegations"
      },
      "view": {
        "crumb": "View",
        "navTitle": "View",
        "title": "View Delegation"
      }
    },
    "device": {
      "alarm": {
        "crumb": "Alarm",
        "list": {
          "actions": {
            "filter": "Filter",
            "search": "Search",
            "sort": {
              "label": "Sort",
              "options": {
                "raisedAt": "Raised At",
                "resolvedAt": "Resolved At",
                "severity": "Alarm Severity"
              }
            }
          },
          "crumb": "Alarms",
          "empty": "No alarms found",
          "filtered": "Displaying Filtered Results",
          "item": {
            "description": "Description",
            "raisedAt": "Raised at",
            "resolvedAt": "Resolved at"
          },
          "title": "Device Alarms"
        },
        "navTitle": "Alarm",
        "title": "Alarm"
      },
      "alarms": {
        "actions": {
          "filter": "Filter",
          "search": "Search",
          "sort": {
            "label": "Sort",
            "options": {
              "lastUpdated": "Last Updated",
              "name": "Team Name"
            }
          }
        },
        "crumb": "Alarms",
        "navTitle": "Faults",
        "showResolved": "Show resolved alarms",
        "showUnresolved": "Show unresolved alarms",
        "title": "Device Alarms",
        "titleResolved": "Resolved Device Alarms"
      },
      "appliance-service": {
        "crumb": "Service",
        "details": "Service Details",
        "navTitle": "Service",
        "title": "Service"
      },
      "archive": {
        "body": "<p>This will archive the Device “<b>{name}</b>”.</p><p>This Device will no longer appear in the system. All data will be archived and require a manual process to restore.</p>",
        "bodyInstallerPrivate": "<p>This will remove your access to the Device “<b>{name}</b>”.</p><p>This Device will no longer appear in your list of devices. All data will be hidden and require a manual process to restore.</p>",
        "button": "Archive Device",
        "buttonInstallerPrivate": "Remove Access",
        "crumb": "Archive",
        "crumbInstallerPrivate": "Remove",
        "messages": {
          "server": "Server Error",
          "success": "Device Archived"
        },
        "navTitle": "Archive",
        "navTitleInstallerPrivate": "Remove",
        "title": "Archive Device",
        "titleInstallerPrivate": "Remove Access"
      },
      "data": {
        "crumb": "Data",
        "navTitle": "Data",
        "title": "Data"
      },
      "data-visualisation": {
        "crumb": "Data Visualisation",
        "loadingState": "Gathering Chart Data",
        "navTitle": "Data Visualisation",
        "title": "Data Visualisation"
      },
      "details": {
        "archivedAt": {
          "label": "Archived at"
        },
        "boilerBrand": {
          "label": "Boiler Brand",
          "tip": "The boiler brand"
        },
        "boilerSerialNumber": {
          "label": "Boiler Serial Number",
          "tip": "The serial number of the boiler"
        },
        "boilerType": {
          "label": "Boiler Model",
          "tip": "The boiler model"
        },
        "heatPumpBrand": {
          "label": "Heat Pump Brand",
          "tip": "The heat pump brand"
        },
        "heatPumpSerialNumber": {
          "label": "Heat Pump Serial Number",
          "tip": "The serial number of the heat pump"
        },
        "heatPumpType": {
          "label": "Heat Pump Model",
          "tip": "The heat pump model"
        },
        "lastServiceAt": {
          "label": "Last Service at",
          "tip": "{date}"
        },
        "nextServiceDue": {
          "label": "Next Service Due",
          "tip": "{date}"
        },
        "property": {
          "label": "Address",
          "tip": "The address of property the device is installed at"
        },
        "propertySize": {
          "label": "Property Size",
          "tip": "The size of property the device is installed at"
        },
        "propertyType": {
          "label": "Property Type",
          "tip": "The type of property the device is installed at"
        },
        "registeredAt": {
          "label": "Registered at",
          "tip": "{date}"
        },
        "registeredBy": {
          "label": "Registered by",
          "tip": "The portal user who registered this device"
        },
        "registrationType": {
          "label": "Registration Type",
          "tip": "The registration type (Social/Private)"
        },
        "serviceIntervalDays": {
          "label": "Service Interval Days",
          "tip": "Recommended number of days between services"
        }
      },
      "edit": {
        "crumb": "Boiler Information",
        "messages": {
          "cancel": "Changes Reset",
          "server": "Server Error",
          "success": "Device Updated",
          "validation": "Validation Error"
        },
        "navTitle": "Boiler Information",
        "title": "Boiler Information"
      },
      "filter": {
        "Installation": {
          "label": "Installed In",
          "placeholder": "Last 30 Days",
          "tip": "Whether a device is installed in the last 30 days"
        },
        "ServiceDue": {
          "label": "Service Due",
          "placeholder": "Overdue",
          "tip": "Whether a device is due for a service"
        },
        "archive": {
          "label": "Archived",
          "placeholder": "Exclude",
          "tip": "Include or exclude archived/unregistered devices"
        },
        "boilerType": {
          "label": "Boiler model",
          "placeholder": "All Boiler Model",
          "tip": "The type of boiler"
        },
        "clear": "Clear Filter",
        "hasAlarms": {
          "label": "Alarms",
          "placeholder": "Any",
          "tip": "Whether a device has alarms"
        },
        "hasFaults": {
          "label": "Faults",
          "placeholder": "Any",
          "tip": "Whether a device has faults"
        },
        "priority": {
          "label": "Priority Properties",
          "placeholder": "Any",
          "tip": "Only show devices assigned to priority properties"
        },
        "status": {
          "label": "Device status",
          "placeholder": "All Status'",
          "tip": "Whether the device is currently online"
        },
        "submit": "Update Filter",
        "type": {
          "label": "Module type",
          "placeholder": "All Module Types",
          "tip": "The type of module installed in the appliance"
        }
      },
      "form": {
        "boilerSerialNumber": {
          "editTip": "The serial number of the boiler",
          "label": "Boiler Serial Number",
          "placeholder": null,
          "tip": "The serial number of the boiler (optional)"
        },
        "boilerType": {
          "label": "Boiler Model",
          "placeholder": "Select boiler model",
          "tip": "The type of boiler (optional)"
        },
        "footer": {
          "cancel": "Cancel",
          "save": "Save"
        },
        "heatPumpSerialNumber": {
          "editTip": "The serial number of the heat pump",
          "label": "Heat Pump Serial Number",
          "placeholder": null,
          "tip": "The serial number of the heat pump (optional)"
        },
        "lastServiceAt": {
          "label": "Last Service At",
          "placeholder": "dd/mm/yyyy",
          "tip": "The last time the appliance was serviced"
        },
        "nextServiceAt": {
          "label": "Next Service At",
          "placeholder": "dd/mm/yyyy",
          "tip": "The next time the appliance need to be serviced"
        },
        "performService": {
          "button": "Perform Service",
          "confirm": "Confirm",
          "message": "By clicking \"Confirm\" you are acknowledging that you have performed a service on this appliance and agreed on the next service date.",
          "nextServiceLabel": "Next service due on:",
          "servicePerformedLabel": "Service performed on:",
          "success": "Service Period Updated",
          "title": "Perform Service",
          "updateAppliance": "Update Appliance Information"
        },
        "scheduleEvent": {
          "close": "Close",
          "comfortAt": "Comfort at:",
          "comfortTemp": "Comfort Temp:",
          "endTime": "End Time:",
          "event": "Event",
          "noEvents": "No events",
          "setbackAt": "Setback at:",
          "setbackTemp": "Setback Temp:",
          "setpoints": "Setpoints",
          "startTime": "Start Time:",
          "status": "Status:",
          "targetTemp": "Target Temp:"
        },
        "serviceInterval": {
          "label": "Service Interval",
          "placeholder": "365",
          "tip": "The number of days between appliance boiler services"
        }
      },
      "gas-consumption": {
        "boilerModel": {
          "label": "Boiler Model",
          "tip": "The model of the boiler"
        },
        "crumb": "Gas Consumption",
        "dayPowerUseage": {
          "label": "24 Hour Total (KWH)",
          "tip": "The amount of power used in 24 hours"
        },
        "detail": {
          "body": "<p>To see how we calculate the total gas consumption used for devices, and validate the estimated values produced by the equation email us at enquires@idealheating.com</p>",
          "title": "Details"
        },
        "detail-summary": {
          "title": "Summary"
        },
        "details": "Gas Consumption Details",
        "header": "Estimated Gas Consumption Calculation",
        "maxChFanSpeed": {
          "label": "Max CH Fan Speed",
          "tip": "The maximum central heating fan speed"
        },
        "maxDhwFanSpeed": {
          "label": "Max DHW Fan Speed",
          "tip": "The maximum domestic hot water fan speed"
        },
        "minFanSpeed": {
          "label": "Min Fan Speed",
          "tip": "The minimum fan speed"
        },
        "monthPowerUseage": {
          "label": "Month Total (KWH)",
          "tip": "The amount of power used in a month"
        },
        "navTitle": "Gas Consumption",
        "table": {
          "header": {
            "date": "Date",
            "fanAggregate": "Fan Aggregate (Ch)",
            "flameOnDuration": "Flame on Duration (Ch)",
            "gasConsumption": "Calculated Gas Consumption (kWh)",
            "time": "Time"
          }
        },
        "title": "Gas Consumption",
        "weekPowerUseage": {
          "label": "Week Total (KWH)",
          "tip": "The amount of power used in a week"
        },
        "yearPowerUseage": {
          "label": "Year Total (KWH)",
          "tip": "The amount of power used in a year"
        }
      },
      "gasConsumption": {
        "crumb": "Gas Consumption",
        "navTitle": "Gas Consumption",
        "title": "Gas Consumption"
      },
      "halo-details": {
        "firmwareGroup": {
          "label": "Firmware Group",
          "tip": "The device's firmware group"
        },
        "firmwareVersion": {
          "label": "Firmware Version",
          "tip": "The device's firmware version"
        },
        "haloType": {
          "label": "Halo Type",
          "tip": "The type of the Halo device"
        },
        "lastSeenAt": {
          "label": "Last Reported In",
          "tip": "When the device last reported in"
        },
        "moduleType": {
          "label": "Module Type",
          "tip": "The module type (Link (Boiler), Link (Heat Pump), iD-C, Halo, Halo Air)"
        },
        "prt": {
          "batteryLevel": {
            "label": "Battery Level",
            "tip": "The device's battery level"
          },
          "chSetpoints": {
            "title": "Halo Heating"
          },
          "childLock": {
            "label": "Child Lock",
            "tip": "Child lock status (Active/Inactive)"
          },
          "delayedStartEnabled": {
            "label": "Delayed Start Enabled",
            "tip": "(Active/Inactive)"
          },
          "dhwSetpoint": {
            "title": "Halo Hot Water"
          },
          "firmwareVersionPrt": {
            "label": "Firmware Version",
            "tip": "The device's firmware version (PRT)"
          },
          "flameState": {
            "label": "Flame State",
            "tip": "The device's flame state"
          },
          "heatingSetpoint": {
            "label": "Heating Setpoint",
            "tip": "The device's heating setpoint"
          },
          "holidayMode": {
            "label": "Holiday Mode",
            "tip": "The device's holiday mode"
          },
          "holidayModeEnd": {
            "label": "Holiday Mode End",
            "tip": "The device's holiday mode end"
          },
          "identifyModeActive": {
            "label": "Identify Mode Active",
            "tip": "(Active/Inactive)"
          },
          "lastMessageRssi": {
            "label": "Last Message RSSI",
            "tip": "Last message signal strength"
          },
          "localTemperature": {
            "label": "Local Temperature",
            "tip": "The device's local temperature"
          },
          "modelNumber": {
            "label": "Model Number",
            "tip": "The device's model number (PRT)"
          },
          "optimisedStart": {
            "label": "Optimised Start",
            "tip": "(Duration/Off)"
          },
          "optimisedStartEnabled": {
            "label": "Optimised Start Enabled",
            "tip": "(Active/Inactive)"
          },
          "optimisedStop": {
            "label": "Optimised Stop",
            "tip": "(Duration/Off)"
          },
          "optimisedStopEnabled": {
            "label": "Optimised Stop Enabled",
            "tip": "(Active/Inactive)"
          },
          "overrideEnd": {
            "label": "Next Event",
            "tip": "When the next event will start"
          },
          "schedule": {
            "select": "Select Schedule",
            "title": "Schedule: {selected}"
          },
          "temperatureMode": {
            "label": "Temperature Mode",
            "tip": "The device's temperature mode"
          }
        },
        "serialNumber": {
          "label": "Serial Number",
          "tip": "The devices unique serial"
        },
        "si": {
          "bootupReason": {
            "label": "Boot-up Reason",
            "tip": "Boot-up reason code"
          },
          "deviceId": {
            "label": "Device ID",
            "tip": "The device's identifying number"
          },
          "failSafeMode": {
            "label": "Fail-safe Mode",
            "tip": "Fail-safe mode status (Active/Inactive)"
          },
          "heatCurve": {
            "label": "Heat Curve",
            "tip": "Is the heat curve active (Active/Inactive)"
          },
          "heatCurveNumber": {
            "label": "Heat Curve Number",
            "tip": "The heat curve coefficient number"
          },
          "networkRssi": {
            "label": "Network RSSI",
            "tip": "The device's local signal strength"
          },
          "networkSsid": {
            "label": "Network Name",
            "tip": "The name of the network connected to"
          },
          "override": {
            "label": "Override",
            "tip": "Override mode status (Active/Inactive)"
          },
          "pairingMode": {
            "label": "Pairing Mode",
            "tip": "The device's pairing mode status (Active/Inactive)"
          },
          "powerupTime": {
            "label": "Power-up Time",
            "tip": "{date}"
          },
          "weatherOptimisation": {
            "label": "Weather Optimisation",
            "tip": "Optimising for the weather (Active/Inactive)"
          },
          "zigbeeChannel": {
            "label": "Zigbee Channel",
            "tip": "The Zigbee channel the device is using"
          }
        },
        "status": {
          "label": "Connectivity",
          "tip": "Device connectivity to the cloud"
        }
      },
      "homeowner": {
        "crumb": "Homeowners",
        "navTitle": "Homeowners",
        "title": "Homeowners"
      },
      "hpd-live-data": {
        "activeReadings": {
          "title": "Active Readings"
        },
        "connected": "Connnected",
        "connection": "Connection",
        "information": {
          "title": "Information"
        },
        "operation": {
          "title": "Operation"
        },
        "performance": {
          "title": "Performance"
        },
        "setValues": {
          "title": "Set Values"
        },
        "title": "Live Data"
      },
      "idc-details": {
        "firmwareVersion": {
          "label": "Firmware Version",
          "tip": "The device's app firmware version"
        },
        "lastSeenAt": {
          "label": "Last Reported In",
          "tip": "When the device last reported in"
        },
        "moduleType": {
          "label": "Module Type",
          "tip": "The module type (Link (Boiler), Link (Heat Pump), iD-C, Halo, Halo Air)"
        },
        "serialNumber": {
          "label": "Serial Number",
          "tip": "The devices unique serial"
        },
        "signalQuality": {
          "label": "Network Signal Quality",
          "tip": "The device's local signal quality"
        },
        "signalStrength": {
          "label": "Network Signal Strength",
          "tip": "The device's local signal strength"
        },
        "status": {
          "label": "Connectivity",
          "tip": "Device connectivity to the cloud"
        }
      },
      "license": {
        "cloudConnectionToggle": {
          "label": "Cloud Connection",
          "tip": "This device has access to app & cloud features"
        },
        "crumb": "License",
        "footer": {
          "cancel": "Cancel",
          "save": "Save",
          "server": "Server Error",
          "validation": "Validation Error"
        },
        "messages": {
          "cancel": "License changes cancelled",
          "server": "Server error",
          "success": "License details successfully changed",
          "validation": "Validation Error"
        },
        "navTitle": "License",
        "notes": {
          "label": "Notes",
          "placeholder": "Note",
          "tip": "Add any additional notes"
        },
        "paymentReference": {
          "label": "Payment reference",
          "placeholder": "Payment reference",
          "tip": "The payment reference of the license"
        },
        "supportTip": "Before activating the upgrade ensure the customer closes the app",
        "title": "License"
      },
      "list": {
        "actions": {
          "export": {
            "button": "Download",
            "label": "Export",
            "text": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius aliquam laudantium explicabo pariatur iste dolorem animi vitae error totam. At sapiente aliquam accusamus facere veritatis.",
            "title": "Export Devices"
          },
          "filter": "Filter",
          "new": "Add New Device",
          "search": "Search",
          "sort": {
            "label": "Sort",
            "options": {
              "lastServiceAt": "Service date",
              "priority": "Priority Property",
              "property": "Property (Postcode)"
            }
          },
          "view-map": "View map"
        },
        "crumb": "Devices",
        "empty": "No devices found",
        "filtered": "Displaying Filtered Results",
        "item": {
          "alarm": "alarm",
          "archived": "Archived",
          "noAlarm": "No alarm",
          "offline": "Disconnected",
          "online": "Connected",
          "service": "Service Due",
          "zip": "Postcode"
        },
        "messages": {
          "export": "Export Started"
        },
        "title": "List Devices"
      },
      "live-data": {
        "configurationTitle": "Configuration Data",
        "connected": "Connnected",
        "connection": "Connection",
        "crumb": "Live Data",
        "deviceOffline": "The device is currently offline",
        "download": "Download",
        "downloadBody": "Download telemetry data for device",
        "downloadTelemetry": "Download Telemetry",
        "endDateTime": "Until:",
        "endDateTimeTip": "Up to this end date",
        "highPriorityTitle": "Priority Information",
        "hpModel": {
          "label": "Heat Pump Model",
          "tip": "The model of the heat pump"
        },
        "idcTelemetryInfo": "Be aware that the iD-C data is reported every fifteen minutes and it is not reported live",
        "idcTitle": "Data",
        "liveDataTitle": "Live data",
        "loadingState": "Gathering Telemetry Data, please do not navigate away from this page",
        "lowPriorityTitle": "Extra Information",
        "lowReportingRate": "Reporting rate updating",
        "modalMaxCommandLabel": "Maximum:",
        "modalMaxValueErrorLabel": "does not meet maximum value",
        "modalMinCommandLabel": "Minimum:",
        "modalMinValueErrorLabel": "does not meet minimum value",
        "modalTitle": "Update Data",
        "navTitle": "Live Data",
        "notConnected": "Not Connected",
        "rvs21Firmware": {
          "label": "RVS21 Firmware",
          "tip": "The device's firmware version"
        },
        "startDateTime": "Download from:",
        "startDateTimeTip": "The start date for your download",
        "title": "Live Data",
        "websocketNotConnected": "Websocket not connected, attempting to reconnect"
      },
      "logic-details": {
        "appVersion": {
          "label": "Firmware Version",
          "tip": "The device's app firmware version"
        },
        "boilerProtocol": {
          "label": "Boiler Protocol",
          "tip": "The device's boiler protocol"
        },
        "hardwareVersion": {
          "label": "Hardware Version",
          "tip": "The device's hardware version"
        },
        "moduleType": {
          "label": "Module Type",
          "tip": "The module type (Link (Boiler), Link (Heat Pump), iD-C, Halo, Halo Air)"
        },
        "network": {
          "label": "Network Name",
          "tip": "The name of the network connected to"
        },
        "networkAccessTechnology": {
          "label": "Network Access Technology",
          "tip": "The method used to connect to the cloud"
        },
        "networkRssi": {
          "label": "Network RSSI",
          "tip": "The device's local signal strength"
        },
        "resetReason": {
          "label": "Reset Reason",
          "tip": "The reason the device last reset"
        },
        "serialNumber": {
          "label": "Serial Number",
          "tip": "The devices unique serial"
        },
        "status": {
          "label": "Connectivity",
          "tip": "Device connectivity to the cloud"
        },
        "telemetryInterval": {
          "label": "Telemetry Interval",
          "tip": "The rate that telemetry is current configured to be sent at"
        }
      },
      "map": {
        "actions": {
          "view-list": "View list"
        },
        "address": "Address",
        "crumb": "Map",
        "device-details": {
          "boilerBrand": {
            "label": "Boiler Brand",
            "tip": "The boiler brand"
          },
          "boilerType": {
            "label": "Boiler Model",
            "tip": "The boiler model"
          },
          "heatPumpBrand": {
            "label": "Heat Pump Brand",
            "tip": "The heat pump brand"
          },
          "heatPumpType": {
            "label": "Heat Pump Model",
            "tip": "The heat pump model"
          },
          "lastSeenAt": {
            "label": "Last Reported In",
            "tip": "When the device last reported in"
          },
          "lastServiceAt": {
            "label": "Last Service at",
            "tip": "{date}"
          },
          "moduleType": {
            "label": "Module Type",
            "tip": "The module type (Link (Boiler), Link (Heat Pump), iD-C, Halo, Halo Air)"
          },
          "property": {
            "label": "Address",
            "tip": "The address of property the device is installed at"
          },
          "registeredAt": {
            "label": "Registered at",
            "tip": "{date}"
          },
          "serialNumber": {
            "label": "Serial Number",
            "tip": "The devices unique serial"
          },
          "status": {
            "label": "Connectivity",
            "tip": "Device connectivity to the cloud"
          },
          "viewDevice": {
            "label": "View Device"
          }
        },
        "navTitle": "Map",
        "title": "Map Devices"
      },
      "map-view": {
        "crumb": "Map View",
        "navTitle": "Map View",
        "title": "Device Location"
      },
      "nav": {
        "icon": "nav.users",
        "label": "Devices"
      },
      "new": {
        "crumb": "New",
        "messages": {
          "server": "Server Error",
          "success": "Device Created",
          "validation": "Validation Error"
        },
        "title": "New Device"
      },
      "si": {
        "crumb": "Smart Interface",
        "navTitle": "Smart Interface",
        "title": "Smart Interface"
      },
      "si-command": {
        "crumb": "Interface Commands",
        "details": {
          "ackType": {
            "label": "Command Status",
            "tip": "The status of the command (Successful, Processing, Failed)"
          },
          "cognitoId": {
            "label": "Cognito Id",
            "tip": "The Cognito Id of the user that sent the command"
          },
          "commandName": {
            "label": "Command Name",
            "tip": "The name of the command that was executed"
          },
          "crumb": "View",
          "deviceToken": {
            "label": "Device Token",
            "tip": "The Id of the mobile phone that sent the command"
          },
          "failed": "Failed",
          "processing": "Processing",
          "receivedAt": {
            "label": "Received At",
            "tip": "When the command was received by the system"
          },
          "requestData": {
            "label": "Request Data",
            "tip": "The command payload in JSON format"
          },
          "siId": {
            "label": "Interface Id",
            "tip": "The Id of the Interface"
          },
          "success": "Successful",
          "title": "Interface Command Details"
        },
        "header": "View Interface Command",
        "list": {
          "empty": "No Interface Commands found",
          "receivedAt": "Received at"
        },
        "navTitle": "Interface Commands",
        "title": "Interface Commands"
      },
      "system-dashboard": {
        "boilerType": "Boiler Type",
        "crumb": "System Dashboard",
        "details": "Appliance Details",
        "device-details": "Device Details",
        "heatPumpDetails": "Heat Pump Details",
        "heatPumpType": "Heat Pump Type",
        "messages": {
          "server": "Server Error",
          "success": "Device Data Successfully Updated"
        },
        "navTitle": "System Dashboard",
        "title": "System Dashboard",
        "togglePriority": "Toggle Priority"
      },
      "telemetry": {
        "average": {
          "label": "Average",
          "tip": "The average value of telemetry metrics"
        },
        "boilerInformation": "Boiler Information",
        "boilerSetpoint": "Boiler Setpoint",
        "boilerStatus": "Boiler Status",
        "chIgnitions": "CH Ignitions",
        "chRuntime": "CH Runtime",
        "ch_return_temperature": "Return Temp",
        "ch_water_pressure": "Water Pressure",
        "count": {
          "label": "Count",
          "tip": "The number of telemetry metrics received"
        },
        "dhwCall": "DHW Call",
        "dhwIgnitions": "DHW Ignitions",
        "dhwRuntime": "DHW Runtime",
        "dhw_temperature": "DHW Temp",
        "exhaust_temperature": "Boiler Flue Temp",
        "flame_current": "Ionization Current",
        "flow_water_temperature": "Supply Temp",
        "heatingCall": "Heating Call",
        "item": {
          "null": "N/A"
        },
        "lastChanged": "Last Changed",
        "loading": "Loading Telemetry…",
        "max": {
          "label": "Max",
          "tip": "The maximum value of telemetry metrics"
        },
        "min": {
          "label": "Min",
          "tip": "The minimum value of telemetry metrics"
        },
        "outside_temperature": "Outside Temp",
        "relative_modulation_level": "Boiler Firing Rate",
        "title": "Telemetries"
      },
      "view": {
        "crumb": "View",
        "details": "Appliance Details",
        "device-details": "Device Details",
        "heatPumpDetails": "Heat Pump Details",
        "navTitle": "View",
        "priority": "Priority",
        "property": "Property",
        "title": "View Device",
        "togglePriority": "Toggle Priority"
      }
    },
    "external": {
      "confirmUser": {
        "byline": "To verify your email address, we have sent you a six digit pin code from donotreply@installeridealheating.com",
        "form": {
          "back": "Back to Login",
          "email": {
            "label": "Email",
            "placeholder": "name@example.com",
            "tip": "This is usually your work email address"
          },
          "resend": "Resend Verification Code",
          "submit": "Verify",
          "verificationCode": {
            "label": "Verification Code",
            "placeholder": "Enter your verification code",
            "tip": "This will have been emailed to you"
          }
        },
        "messages": {
          "resendSuccess": "Verfication Code resent!",
          "server": "Server Error",
          "success": "Confirmed!",
          "unknownUser": "User does not exist",
          "validation": "Validation Error"
        },
        "title": "Verify your email?"
      },
      "invite": {
        "byline": "Set a password to start using the Ideal Heating portal",
        "title": "Register your account"
      },
      "login": {
        "byline": "Or contact an administrator if you do not have an account",
        "form": {
          "confirmUser": "Verify your email?",
          "email": {
            "label": "Email",
            "placeholder": "name@example.com",
            "tip": "This is usually your work email address"
          },
          "forgot": "Forgot Password?",
          "newUser": "Create an account?",
          "password": {
            "label": "Password",
            "placeholder": "Enter your password",
            "tip": "Your password to the portal"
          },
          "submit": "Login"
        },
        "messages": {
          "incorrectEmailOrPassword": "Incorrect email or password entered, please check spelling and try again",
          "server": "Server Error",
          "success": "Welcome!",
          "unknownUser": "User does not exist",
          "validation": "Incorrect email or password entered, please check spelling and try again"
        },
        "title": "Sign in to your account"
      },
      "maintenance": {
        "description": "The site is currently down for maintenance, please try again later.",
        "title": "Down for maintenance"
      },
      "new-password": {
        "byline": "Almost there! Set a new password to access Ideal Heating portal",
        "form": {
          "back": "Back to Login",
          "password": {
            "label": "New Password",
            "placeholder": "Enter a new password",
            "tip": "Passwords must be at least 8 characters long, must contain at least one lowercase character, uppercase character, special character and number"
          },
          "passwordConfirmation": {
            "label": "Confirm Password",
            "placeholder": "Enter new password",
            "tip": "Please confirm your new password"
          },
          "submit": "Set Password"
        },
        "messages": {
          "server": "Server Error",
          "success": "Password Updated",
          "validation": "Validation Error"
        },
        "title": "Set your password"
      },
      "newAccount": {
        "form": {
          "acceptTerms": {
            "PrivacyPolicyLink": "Privacy Policy",
            "TermsAndConditionsLink": "Terms & Conditions",
            "label": "I agree to the Terms & Conditions and the Privacy Policy"
          },
          "email": {
            "label": "Email Address",
            "placeholder": "Enter your email address",
            "tip": "Please enter your email address"
          },
          "password": {
            "label": "New Password",
            "placeholder": "Enter a new password",
            "tip": "Passwords must be at least 8 characters long, must contain at least one lowercase character, uppercase character, special character and number"
          },
          "passwordConfirmation": {
            "label": "Confirm Password",
            "placeholder": "Enter new password",
            "tip": "Please confirm your new password"
          },
          "submit": "Create an account"
        },
        "title": "Create an account"
      },
      "recover": {
        "byline": "No problem, enter your account's email and we'll send a reset link to your inbox.",
        "form": {
          "back": "Back to Login",
          "email": {
            "label": "Email",
            "placeholder": "name@example.com",
            "tip": "This is usually your work email address"
          },
          "gotCode": "Already Got a Code?",
          "submit": "Send Me a Code"
        },
        "messages": {
          "server": "Server Error",
          "success": "Password Email Sent",
          "unknownUser": "User does not exist",
          "validation": "Validation Error"
        },
        "title": "Forgot your password?"
      },
      "reset": {
        "byline": "Almost there! Set a new password to access Ideal Heating portal again",
        "form": {
          "back": "Back to Login",
          "password": {
            "label": "New Password",
            "placeholder": "Enter a new password",
            "tip": "Passwords must be at least 8 characters long, must contain at least one lowercase character, uppercase character, special character and number"
          },
          "passwordConfirmation": {
            "label": "Confirm Password",
            "placeholder": "Enter new password",
            "tip": "Please confirm your new password"
          },
          "submit": "Change Password",
          "verificationCode": {
            "label": "Verification Code",
            "placeholder": "Enter your verification code",
            "tip": "This will have been emailed to you"
          }
        },
        "messages": {
          "server": "Server Error",
          "success": "Password Updated",
          "validation": "Validation Error"
        },
        "title": "Reset your password"
      }
    },
    "footer": {
      "version": "Version"
    },
    "form": {
      "footer": {
        "cancel": "Cancel",
        "save": "Save",
        "serverErrors": "Server Error",
        "validationErrors": "Validation Errors"
      }
    },
    "help": {
      "faq": {
        "button": {
          "description": "Click the button below to open the Ideal Heating FAQs in a new tab.",
          "text": "Go to FAQs"
        },
        "description": "Find information and frequently asked questions about Ideal Heating products over on the Ideal Heating website.",
        "title": "Want some help?"
      },
      "nav": {
        "crumb": "Help",
        "label": "Help"
      },
      "privacy": {
        "button": {
          "description": "Click the button below to open the Ideal Heating Privacy Policy in a new tab.",
          "text": "Go to Privacy Policy"
        },
        "description": "Find our Privacy Policy on the Ideal Heating website",
        "title": "Privacy Policy"
      },
      "terms": {
        "button": {
          "description": "Click the button below to open the Ideal Heating Terms and Conditions in a new tab.",
          "text": "Go to Terms and Conditions"
        },
        "description": "Find our Terms and Conditions on the Ideal Heating website",
        "title": "Terms and Conditions"
      },
      "title": "Help"
    },
    "home": {
      "nav": {
        "label": "Home"
      },
      "search": {
        "button": "Search it",
        "deviceHeader": "Search for devices:",
        "devicePlaceholder": "Search by serial number or address",
        "homeownerHeader": "Search for customers:",
        "homeownerPlaceholder": "Search by customer name, serial number or MAC address"
      },
      "title": "Welcome to the Ideal Heating portal"
    },
    "homeowner": {
      "archive": {
        "crumb": "Archive",
        "navTitle": "Archive",
        "title": "Archive Device"
      },
      "details": {
        "acceptedMarketing": {
          "label": "Accepted marketing preferences",
          "tip": "This is a tip for Accepted Marketing"
        },
        "createdAt": {
          "label": "Created",
          "tip": "This is a tip for Createdat"
        },
        "createdBy": {
          "label": "Created by",
          "tip": "This is a tip for Createdby"
        },
        "email": {
          "label": "Email",
          "tip": "This is a tip for Email"
        },
        "firstName": {
          "label": "First Name",
          "tip": "This is a tip for first name"
        },
        "lastActive": {
          "label": "Last Active",
          "tip": "This is a tip for Last Active"
        },
        "lastName": {
          "label": "Last Name",
          "tip": "This is a tip for last name"
        },
        "termsAgreedAt": {
          "label": "Terms Agreed At",
          "tip": "This is a tip for Terms Agreed At"
        },
        "termsVersion": {
          "label": "Term Version",
          "tip": "This is a tip for Term Version"
        }
      },
      "devices": {
        "crumb": "Devices",
        "navTitle": "Devices"
      },
      "edit": {
        "crumb": "Edit",
        "label": "Homeowner",
        "messages": {
          "cancel": "Changes Reset",
          "server": "Server Error",
          "success": "Homeowner Updated",
          "validation": "Validation Error"
        },
        "navTitle": "Edit",
        "title": "Edit Homeowner"
      },
      "filter": {
        "acceptedMarketing": {
          "label": "Accepted marketing preferences",
          "tip": "Whether homeowner accepted marketing preferences"
        },
        "clear": "Clear Filter",
        "lastActive": {
          "label": "Last active",
          "placeholder": "Last time homeowner used the app"
        },
        "submit": "Update Filter"
      },
      "form": {
        "acceptedMarketing": {
          "label": "Accepted Marketing",
          "placeholder": "Enter Accepted Marketing"
        },
        "createdAt": {
          "label": "Created at",
          "placeholder": "Enter Createdat",
          "tip": "This is a tip for Createdat"
        },
        "email": {
          "label": "Email",
          "placeholder": "Enter Email"
        },
        "firstName": {
          "label": "First Name",
          "placeholder": "Enter Firstname"
        },
        "lastActive": {
          "label": "Last Active",
          "placeholder": "Enter Last active"
        },
        "lastName": {
          "label": "Last Name",
          "placeholder": "Enter Lastname"
        }
      },
      "list": {
        "actions": {
          "export": {
            "button": "Download",
            "label": "Export",
            "text": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius aliquam laudantium explicabo pariatur iste dolorem animi vitae error totam. At sapiente aliquam accusamus facere veritatis.",
            "title": "Export Homeowners"
          },
          "filter": "Filter",
          "search": "Search",
          "sort": {
            "label": "Sort",
            "options": {
              "email": "Email",
              "name": "Name"
            }
          }
        },
        "crumb": "Homeowners",
        "noResult": "No Homeowners Found",
        "title": "Homeowners"
      },
      "nav": {
        "icon": "nav.users",
        "label": "Homeowners"
      },
      "view": {
        "crumb": "View",
        "details": "Homeowner Details",
        "navTitle": "View",
        "title": "View Homeowner"
      }
    },
    "homeowner-invite": {
      "archive": {
        "body": "<p>This will archive the Homeowner Invite “<b>{name}</b>”.</p><p>This Homeowner Invite will no longer appear in the system. All data will be archived and require a manual process to restore.</p>",
        "button": "Archive Homeowner Invite",
        "crumb": "Archive",
        "messages": {
          "server": "Server Error",
          "success": "Homeowner Invite Archived"
        },
        "navTitle": "Archive",
        "title": "Archive Homeowner Invite"
      },
      "details": {
        "createdAt": {
          "label": "Invited at",
          "tip": "When the homeowner was invited"
        },
        "createdBy": {
          "label": "Invited by",
          "tip": "Who invited the homeowner"
        },
        "createdById": {
          "label": "Created by Id",
          "tip": "This is a tip for Createdbyid"
        },
        "device": {
          "label": "Device",
          "tip": "The device installed at the property"
        },
        "email": {
          "label": "Homeowner email",
          "tip": "The invited homeowner"
        },
        "invitee": {
          "label": "Invitee",
          "tip": "The invited homeowner"
        },
        "property": {
          "label": "Property",
          "tip": "The property the device is installed at"
        },
        "title": "Details"
      },
      "edit": {
        "crumb": "Edit",
        "details": "Details",
        "form": "Update Email",
        "messages": {
          "cancel": "Changes Reset",
          "server": "Server Error",
          "success": "Homeowner Invite Updated",
          "validation": "Validation Error"
        },
        "navTitle": "Edit",
        "remove": "Remove Invite",
        "title": "Edit Homeowner Invite"
      },
      "filter": {
        "clear": "Clear Filter",
        "submit": "Update Filter"
      },
      "form": {
        "createdAt": {
          "label": "Created at",
          "placeholder": "Enter Createdat",
          "tip": "This is a tip for Createdat"
        },
        "createdBy": {
          "label": "Created by",
          "placeholder": "Enter Createdby",
          "tip": "This is a tip for Createdby"
        },
        "createdById": {
          "label": "Created by Id",
          "placeholder": "Enter Createdbyid",
          "tip": "This is a tip for Createdbyid"
        },
        "device": {
          "label": "Device",
          "placeholder": "Enter Device",
          "tip": "This is a tip for Device"
        },
        "email": {
          "label": "Email",
          "placeholder": "Enter New Email",
          "tip": "This will cancel the current invite and send a new invite for the updated email address"
        },
        "performCancelInvite": {
          "button": "Remove",
          "confirm": "Confirm",
          "fail": "Invite failed to update",
          "message": "This will remove the current invite for the home owner to view this device",
          "success": "Invite Removed successfully",
          "title": "Remove Invite"
        },
        "performUpdateEmail": {
          "button": "Update",
          "confirm": "Confirm",
          "fail": "Email address failed to update",
          "message": "This will cancel the current invite and send a new invite for the updated email address",
          "success": "Email address updated successfully",
          "title": "Update Email"
        },
        "property": {
          "label": "Property",
          "placeholder": "Enter Property",
          "tip": "This is a tip for Property"
        },
        "remove": {
          "button": "Remove",
          "placeholder": "Enter Createdbyid",
          "tip": "This is a tip for Createdbyid"
        }
      },
      "list": {
        "actions": {
          "export": {
            "button": "Download",
            "label": "Export",
            "text": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius aliquam laudantium explicabo pariatur iste dolorem animi vitae error totam. At sapiente aliquam accusamus facere veritatis.",
            "title": "Export Homeowner Invites"
          },
          "filter": "Filter",
          "new": "Add New Homeowner Invite",
          "search": "Search",
          "sort": {
            "label": "Sort",
            "options": {
              "boilerSerialNumber": "Boiler serial number",
              "createdAt": "Invited",
              "email": "Homeowner email",
              "heatPumpSerialNumber": "Heat Pump serial number"
            }
          }
        },
        "crumb": "Homeowner Invites",
        "item": {
          "invitedAt": "Invited"
        },
        "messages": {
          "export": "Export Started"
        },
        "title": "List Homeowner Invites"
      },
      "nav": {
        "icon": "nav.users",
        "label": "Homeowner Invites"
      },
      "new": {
        "crumb": "New",
        "messages": {
          "server": "Server Error",
          "success": "Homeowner Invite Created",
          "validation": "Validation Error"
        },
        "title": "New Homeowner Invite"
      },
      "view": {
        "crumb": "View",
        "invited": "invited",
        "navTitle": "View",
        "title": "View Homeowner Invite"
      }
    },
    "ideal-user": {
      "archive": {
        "body": "<p>This will archive the Customer Service User “<b>{name}</b>”.</p><p>This Customer Service User will no longer appear in the system. All data will be archived and require a manual process to restore.</p>",
        "button": "Archive Customer Service User",
        "crumb": "Archive",
        "messages": {
          "server": "Server Error",
          "success": "Customer Service User Archived"
        },
        "navTitle": "Archive",
        "title": "Archive Customer Service User"
      },
      "details": {
        "createdAt": {
          "label": "Created at",
          "tip": "When the user was created"
        },
        "createdBy": {
          "label": "Created by",
          "tip": "The full name of the user who invited this user"
        },
        "createdById": {
          "label": "Created by Id",
          "tip": "The Id of the user who invited this user"
        },
        "email": {
          "label": "Email",
          "tip": "The user's email address"
        },
        "firstName": {
          "label": "First Name",
          "tip": "The user's first name"
        },
        "lastActive": {
          "label": "Last Active",
          "tip": "When the user last used the portal"
        },
        "lastName": {
          "label": "Last Name",
          "tip": "The user's last name"
        },
        "notes": {
          "label": "Notes",
          "tip": "Optional notes associated with the user"
        },
        "role": {
          "label": "Role",
          "tip": "The user's role"
        },
        "updatedAt": {
          "label": "Updated at",
          "tip": "When the user was updated"
        },
        "updatedBy": {
          "label": "Updated by",
          "tip": "The full name of the user who updated this user"
        },
        "updatedById": {
          "label": "Updated by Id",
          "tip": "The Id of the user who updated this user"
        },
        "verified": {
          "label": "Verified",
          "tip": "Whether the user has verified their email address"
        }
      },
      "edit": {
        "crumb": "Edit",
        "messages": {
          "cancel": "Changes Reset",
          "server": "Server Error",
          "success": "Customer Service User Updated",
          "validation": "Validation Error"
        },
        "navTitle": "Edit",
        "title": "Edit Customer Service User"
      },
      "filter": {
        "clear": "Clear Filter",
        "submit": "Update Filter"
      },
      "form": {
        "addressLine1": {
          "label": "Address",
          "placeholder": "Enter address",
          "tip": "The invitees address"
        },
        "email": {
          "label": "Email",
          "placeholder": "Enter Email",
          "tip": "This is usually an invitees work email address"
        },
        "firstName": {
          "label": "First Name",
          "placeholder": "Enter First Name",
          "tip": "The invitees first name"
        },
        "lastName": {
          "label": "Last Name",
          "placeholder": "Enter Last Name",
          "tip": "The invitees last name"
        },
        "role": {
          "label": "Role",
          "placeholder": "Select Role",
          "tip": "The permission level of the invitee"
        },
        "roles": {
          "admin": "Admin",
          "engineer": "Engineer",
          "support": "Support"
        }
      },
      "list": {
        "actions": {
          "filter": "Filter",
          "new": "Add User",
          "search": "Search",
          "sort": {
            "label": "Sort",
            "options": {
              "createdAt": "Created",
              "id": "ID",
              "updatedAt": "Updated"
            }
          }
        },
        "crumb": "Users",
        "title": "Customer Service Users"
      },
      "nav": {
        "icon": "nav.users",
        "label": "Users"
      },
      "new": {
        "crumb": "New",
        "messages": {
          "cancel": "Changes Reset",
          "server": "Server Error",
          "success": "Customer Service User Created",
          "validation": "Validation Error"
        },
        "title": "Customer Support Users"
      },
      "roles": {
        "Admin": "Admin",
        "Engineer": "Engineer",
        "User": "Support"
      },
      "view": {
        "cognitoTitle": "User Authentication Status",
        "crumb": "View",
        "navTitle": "View",
        "title": "View Customer Service User"
      }
    },
    "internal": {
      "completeSignUp": {
        "form": {
          "acceptTerms": {
            "PrivacyPolicyLink": "Privacy Policy",
            "TermsAndConditionsLink": "Terms & Conditions",
            "label": "I agree to the Terms & Conditions and the Privacy Policy"
          },
          "back": "Logout",
          "companyName": {
            "label": "Company Name",
            "placeholder": "Enter your company name",
            "tip": "Please enter your company name"
          },
          "countryCode": {
            "ireland": "Republic Of Ireland(+353)",
            "label": "Country Code",
            "placeholder": "Enter your country code",
            "tip": "Please enter  your country code",
            "uk": "United Kingdom(+44)"
          },
          "firstName": {
            "label": "First Name",
            "placeholder": "Enter your first name",
            "tip": "Please enter your first name"
          },
          "lastName": {
            "label": "Last Name",
            "placeholder": "Enter your last name",
            "tip": "Please enter your last name"
          },
          "phoneNumber": {
            "label": "Phone Number",
            "placeholder": "Enter your phone number",
            "tip": "Please enter your phone number"
          },
          "submit": "Complete"
        },
        "messages": {
          "server": "Server Error",
          "success": "Confirmed!",
          "unknownUser": "User does not exist",
          "validation": "Validation Error"
        },
        "title": "Complete Sign Up"
      },
      "linkPrefix": "Powered by"
    },
    "languages": {
      "en-uk": "English (UK)",
      "en-us": "American English (US)"
    },
    "modal": {
      "cancel": "Cancel"
    },
    "nav": {
      "home": "Home",
      "logout": "Logout",
      "partners": "Groups",
      "settings": "Settings"
    },
    "notFound": {
      "button": "Go Home",
      "subtitle": "Oops. This page has gone missing.",
      "text": "You may have mistyped the address or the page may have moved.",
      "title": "404"
    },
    "pagination": {
      "label": "Results per page"
    },
    "partner": {
      "archive": {
        "body": "<p>This will archive the Group “<b>{name}</b>”.</p><p>This Group will no longer appear in the system. All data will be archived and require a manual process to restore.</p>",
        "button": "Archive Group",
        "crumb": "Archive",
        "messages": {
          "server": "Server Error",
          "success": "Group Archived"
        },
        "navTitle": "Archive",
        "title": "Archive Group"
      },
      "delegated-partners": {
        "crumb": "Delegations",
        "details": "Group Details",
        "navTitle": "Delegations",
        "title": "View Group"
      },
      "details": {
        "addressCity": {
          "label": "City"
        },
        "addressCountry": {
          "label": "Country"
        },
        "addressCounty": {
          "label": "County"
        },
        "addressLine1": {
          "label": "Address Line 1"
        },
        "addressLine2": {
          "label": "Address Line 2"
        },
        "addressPostcode": {
          "label": "Postcode"
        },
        "contactNumber": {
          "label": "Contact Number"
        },
        "createdAt": {
          "label": "Created",
          "tip": "This is a tip for Createdat"
        },
        "createdBy": {
          "label": "Created by",
          "tip": "This is a tip for Createdby"
        },
        "createdById": {
          "label": "Created by Id",
          "tip": "This is a tip for Createdbyid"
        },
        "id": {
          "label": "Id"
        },
        "name": {
          "label": "Name",
          "tip": "This is a tip for Name"
        },
        "propertyCount": {
          "label": "Property Count",
          "tip": "This is a tip for Propertycount"
        },
        "switchContext": {
          "cancel": "Cancel",
          "confirm": "Confirm",
          "message": "You are about to switch to a group context and view the portal as {partner}. Do you wish to continue?",
          "title": "Are you sure?"
        },
        "updatedAt": {
          "label": "Updated",
          "tip": "This is a tip for Updatedat"
        },
        "updatedBy": {
          "label": "Updated by",
          "tip": "This is a tip for Updatedby"
        },
        "updatedById": {
          "label": "Updated by Id",
          "tip": "This is a tip for Updatedbyid"
        },
        "userCount": {
          "label": "Number of users",
          "tip": "This is a tip for Usercount"
        },
        "website": {
          "label": "Website"
        }
      },
      "edit": {
        "crumb": "Edit",
        "label": "Group",
        "messages": {
          "cancel": "Changes Reset",
          "server": "Server Error",
          "success": "Group Updated",
          "validation": "Validation Error"
        },
        "navTitle": "Edit",
        "title": "Edit Group"
      },
      "filter": {
        "clear": "Clear Filter",
        "submit": "Update Filter"
      },
      "form": {
        "addressCity": {
          "label": "City",
          "placeholder": "City"
        },
        "addressCountry": {
          "label": "Country",
          "placeholder": "Country"
        },
        "addressCounty": {
          "label": "County",
          "placeholder": "County"
        },
        "addressLine1": {
          "label": "1st Line of Address",
          "placeholder": "Address Line 1"
        },
        "addressLine2": {
          "label": "2nd Line of Address",
          "placeholder": "Address Line 2"
        },
        "addressPostcode": {
          "label": "Postcode",
          "placeholder": "Postcode"
        },
        "contactNumber": {
          "label": "Contact Number",
          "placeholder": "Enter Contact Number",
          "tip": "This is a central contact number for the group"
        },
        "name": {
          "label": "Group Name",
          "placeholder": "Enter Name",
          "tip": "This is the name for the entire group"
        },
        "website": {
          "label": "Website",
          "placeholder": "Website"
        }
      },
      "list": {
        "actions": {
          "export": {
            "button": "Download",
            "label": "Export",
            "text": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius aliquam laudantium explicabo pariatur iste dolorem animi vitae error totam. At sapiente aliquam accusamus facere veritatis.",
            "title": "Export Groups"
          },
          "filter": "Filter",
          "new": "Add New Group",
          "search": "Search",
          "sort": {
            "label": "Sort",
            "options": {
              "createdAt": "Created",
              "id": "ID",
              "name": "Name",
              "updatedAt": "Updated"
            }
          }
        },
        "crumb": "Groups",
        "messages": {
          "export": "Export Started"
        },
        "title": "List Groups"
      },
      "nav": {
        "icon": "nav.users",
        "label": "Groups"
      },
      "new": {
        "crumb": "New",
        "messages": {
          "cancel": "Changes Reset",
          "server": "Server Error",
          "success": "Group Created",
          "validation": "Validation Error"
        },
        "title": "New Group"
      },
      "transfer-devices": {
        "ToPartner": {
          "label": "To Group"
        },
        "body": "",
        "button": "Transfer Devices",
        "confirm": {
          "acceptance": "I confirm that I have permission to make this change.",
          "label": "Confirmation",
          "tip": "Completing this action will transfer ownership of all devices from one group to another, this action cannot be reversed.<br><br>"
        },
        "crumb": "Transfer",
        "findPartner": "Find Group",
        "footer": {
          "cancel": "Cancel",
          "save": "Transfer"
        },
        "label": "Transfer",
        "messages": {
          "server": "Server Error",
          "success": "Transferred"
        },
        "modal": {
          "confirm": "Confirm",
          "message": "Warning, this action can not be undone",
          "message2": "<p>Do you want to transfer all devices from “<b>{group1}</b>” to \"<b>{group2}</b>”?</p>",
          "title": "Transfer Devices"
        },
        "navTitle": "Transfer",
        "requestedBy": {
          "label": "Requested By",
          "placeholder": "Name",
          "tip": "Please enter the name of the person requesting the transfer"
        },
        "title": "Transfer",
        "toPartnerId": {
          "label": "To Group",
          "placeholder": "xxxxxxxx-xxxx-xxxx-xxxxxxxx-xxxxxxxxxxxx",
          "tip": "This can be found by navigating to the other Group and will be in the format xxxxxxxx-xxxx-xxxx-xxxxxxxx-xxxxxxxxxxxx"
        }
      },
      "view": {
        "crumb": "View",
        "details": "Company Details",
        "navTitle": "View",
        "title": "View Group"
      }
    },
    "partner-access-invite": {
      "archive": {
        "body": "<p>This will archive the Installer Invite “<b>{name}</b>”.</p><p>This Installer Invite will no longer appear in the system. All data will be archived and require a manual process to restore.</p>",
        "button": "Archive Installer Invite",
        "crumb": "Archive",
        "messages": {
          "server": "Server Error",
          "success": "Installer Invite Archived"
        },
        "navTitle": "Archive",
        "title": "Archive Installer Invite"
      },
      "details": {
        "accept": "Accept",
        "createdAt": {
          "label": "Invited at",
          "tip": "When the installer was invited"
        },
        "createdBy": {
          "label": "Invited by",
          "tip": "Who invited the installer"
        },
        "createdById": {
          "label": "Created by Id",
          "tip": "This is a tip for Createdbyid"
        },
        "device": {
          "label": "Device",
          "tip": "The device installed at the property"
        },
        "email": {
          "label": "Installer email",
          "tip": "The invited installer"
        },
        "property": {
          "label": "Property",
          "tip": "The property the device is installed at"
        },
        "reject": "Reject"
      },
      "edit": {
        "crumb": "Edit",
        "messages": {
          "cancel": "Changes Reset",
          "server": "Server Error",
          "success": "Installer Invite Updated",
          "validation": "Validation Error"
        },
        "navTitle": "Edit",
        "title": "Edit Installer Invite"
      },
      "filter": {
        "clear": "Clear Filter",
        "submit": "Update Filter"
      },
      "form": {
        "AddressCity": {
          "label": "Address City",
          "placeholder": "Enter Addresscity",
          "tip": "This is a tip for Addresscity"
        },
        "AddressCountry": {
          "label": "Address Country",
          "placeholder": "Enter Addresscountry",
          "tip": "This is a tip for Addresscountry"
        },
        "AddressCounty": {
          "label": "Address County",
          "placeholder": "Enter Addresscounty",
          "tip": "This is a tip for Addresscounty"
        },
        "AddressLine1": {
          "label": "Address Line1",
          "placeholder": "Enter Addressline1",
          "tip": "This is a tip for Addressline1"
        },
        "AddressLine2": {
          "label": "Address Line2",
          "placeholder": "Enter Addressline2",
          "tip": "This is a tip for Addressline2"
        },
        "AddressPostcode": {
          "label": "Address Postcode",
          "placeholder": "Enter Addresspostcode",
          "tip": "This is a tip for Addresspostcode"
        },
        "CreatedAt": {
          "label": "Created at",
          "placeholder": "Enter Createdat",
          "tip": "This is a tip for Createdat"
        },
        "CreatedBy": {
          "label": "Created by",
          "placeholder": "Enter Createdby",
          "tip": "This is a tip for Createdby"
        },
        "CreatedByName": {
          "label": "Created by Name",
          "placeholder": "Enter Createdbyname",
          "tip": "This is a tip for Createdbyname"
        },
        "Device": {
          "label": "Homeowner Device",
          "placeholder": "Enter device",
          "tip": "This is a tip for device"
        },
        "HomeownerEmail": {
          "label": "Homeowner Email",
          "placeholder": "Enter Homeowneremail",
          "tip": "This is a tip for Homeowneremail"
        },
        "InstallerEmail": {
          "label": "Installer Email",
          "placeholder": "Enter Installeremail",
          "tip": "This is a tip for Installeremail"
        },
        "Partner": {
          "label": "Partner",
          "placeholder": "Enter Partner",
          "tip": "This is a tip for Partner"
        }
      },
      "list": {
        "actions": {
          "export": {
            "button": "Download",
            "label": "Export",
            "text": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius aliquam laudantium explicabo pariatur iste dolorem animi vitae error totam. At sapiente aliquam accusamus facere veritatis.",
            "title": "Export Installer Invites"
          },
          "filter": "Filter",
          "new": "Add New Installer Invite",
          "search": "Search",
          "sort": {
            "label": "Sort",
            "options": {
              "createdAt": "Invited",
              "id": "ID",
              "updatedAt": "Updated"
            }
          }
        },
        "crumb": "Installer Invites",
        "item": {
          "invitedAt": "Invited"
        },
        "messages": {
          "export": "Export Started"
        },
        "title": "List Installer Invites"
      },
      "nav": {
        "icon": "nav.users",
        "label": "Installer Invites"
      },
      "new": {
        "crumb": "New",
        "messages": {
          "server": "Server Error",
          "success": "Installer Invite Created",
          "validation": "Validation Error"
        },
        "title": "New Installer Invite"
      },
      "view": {
        "crumb": "View",
        "details": "Details",
        "invited": "invited",
        "navTitle": "View",
        "title": "View Installer Invite"
      }
    },
    "partner-user": {
      "archive": {
        "body": "<p>This will archive the User “<b>{name}</b>”.</p><p>This User will no longer appear in the system. All data will be archived and require a manual process to restore.</p>",
        "button": "Archive User",
        "confirmDescription": "By clicking \"Confirm\" you are acknowledging that you are performing an archive action and any archived data requires a manual process to restore.",
        "crumb": "Archive",
        "messages": {
          "server": "Server Error",
          "success": "User Archived"
        },
        "navTitle": "Archive",
        "title": "Archive User"
      },
      "details": {
        "cognitoUpdatedAt": {
          "label": "Cognito Last Updated"
        },
        "createdAt": {
          "label": "Created at",
          "tip": "This is a tip for Createdat"
        },
        "createdBy": {
          "label": "Created by",
          "tip": "This is a tip for Createdby"
        },
        "createdById": {
          "label": "Created by Id",
          "tip": "This is a tip for Createdbyid"
        },
        "email": {
          "label": "Email",
          "tip": "This is usually an invitees work email address"
        },
        "emailVerified": {
          "label": "Email Verified"
        },
        "firstName": {
          "label": "First Name",
          "tip": "The invitees first name"
        },
        "lastActive": {
          "label": "Last Active",
          "tip": "This is a tip for Lastactive"
        },
        "lastName": {
          "label": "Last Name",
          "tip": "The invitees last name"
        },
        "notes": {
          "label": "Notes",
          "tip": "Optional notes associated with the user"
        },
        "partner": {
          "label": "Partner",
          "tip": "This is a tip for Partner"
        },
        "role": {
          "label": "Role",
          "tip": "The user's role"
        },
        "status": {
          "label": "Status",
          "tip": "The Cognito Status"
        },
        "updatedAt": {
          "label": "Updated at",
          "tip": "This is a tip for Updatedat"
        },
        "updatedBy": {
          "label": "Updated by",
          "tip": "This is a tip for Updatedby"
        },
        "updatedById": {
          "label": "Updated by Id",
          "tip": "This is a tip for Updatedbyid"
        },
        "verified": {
          "label": "Verified",
          "tip": "Whether the user has completed user sign up"
        }
      },
      "edit": {
        "crumb": "Edit",
        "messages": {
          "cancel": "Changes Reset",
          "server": "Server Error",
          "success": "User Updated",
          "validation": "Validation Error"
        },
        "navTitle": "Edit",
        "title": "Edit Partner User"
      },
      "email": {
        "crumb": "Email Notifications",
        "messages": {
          "cancel": "Changes Reset",
          "server": "Server Error",
          "success": "User Updated",
          "validation": "Validation Error"
        },
        "navTitle": "Email Notifications",
        "title": "Edit User Email Notifications"
      },
      "filter": {
        "clear": "Clear Filter",
        "email": {
          "label": "Email",
          "placeholder": "name@example.com",
          "tip": "The target email address"
        },
        "firstName": {
          "label": "First name",
          "placeholder": "Enter first name",
          "tip": "The target first name"
        },
        "lastName": {
          "label": "Last name",
          "placeholder": "Enter last name",
          "tip": "The target last name"
        },
        "partnerRole": {
          "label": "Role",
          "placeholder": "Enter role",
          "tip": "The target role"
        },
        "submit": "Update Filter"
      },
      "form": {
        "createdAt": {
          "label": "Created at",
          "placeholder": "Enter Createdat",
          "tip": "This is a tip for Createdat"
        },
        "createdBy": {
          "label": "Created by",
          "placeholder": "Enter Createdby",
          "tip": "This is a tip for Createdby"
        },
        "createdById": {
          "label": "Created by Id",
          "placeholder": "Enter Createdbyid",
          "tip": "This is a tip for Createdbyid"
        },
        "email": {
          "label": "Email",
          "placeholder": "Enter Email",
          "tip": "This is usually an invitees work email address"
        },
        "firstName": {
          "label": "First Name",
          "placeholder": "Enter First Name",
          "tip": "The invitees first name"
        },
        "lastActive": {
          "label": "Last Active",
          "placeholder": "Enter Lastactive",
          "tip": "This is a tip for Lastactive"
        },
        "lastName": {
          "label": "Last Name",
          "placeholder": "Enter Last Name",
          "tip": "The invitees last name"
        },
        "notes": {
          "label": "Notes",
          "placeholder": "Enter Notes",
          "tip": "This is a tip for Notes"
        },
        "partner": {
          "label": "Partner",
          "placeholder": "Enter Partner",
          "tip": "This is a tip for Partner"
        },
        "partnerRole": {
          "label": "Partner Role",
          "placeholder": "Enter Partnerrole",
          "tip": "This is a tip for Partnerrole"
        },
        "role": {
          "label": "Role",
          "placeholder": "Select Role",
          "tip": "The permission level of the invitee"
        },
        "roles": {
          "admin": "Admin",
          "user": "User"
        },
        "updatedAt": {
          "label": "Updated at",
          "placeholder": "Enter Updatedat",
          "tip": "This is a tip for Updatedat"
        },
        "updatedBy": {
          "label": "Updated by",
          "placeholder": "Enter Updatedby",
          "tip": "This is a tip for Updatedby"
        },
        "updatedById": {
          "label": "Updated by Id",
          "placeholder": "Enter Updatedbyid",
          "tip": "This is a tip for Updatedbyid"
        }
      },
      "list": {
        "actions": {
          "export": {
            "button": "Download",
            "label": "Export",
            "text": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius aliquam laudantium explicabo pariatur iste dolorem animi vitae error totam. At sapiente aliquam accusamus facere veritatis.",
            "title": "Export Users"
          },
          "filter": "Filter",
          "new": "Add User",
          "search": "Search",
          "sort": {
            "label": "Sort",
            "options": {
              "createdAt": "Created",
              "id": "ID",
              "updatedAt": "Updated"
            }
          },
          "upload": "Upload Users"
        },
        "crumb": "Users",
        "messages": {
          "export": "Export Started"
        },
        "roles": {
          "Admin": "Admin",
          "User": "User"
        },
        "title": "Users"
      },
      "nav": {
        "icon": "nav.users",
        "label": "Users"
      },
      "new": {
        "crumb": "New",
        "messages": {
          "cancel": "Changes Reset",
          "server": "Server Error",
          "success": "User Created",
          "validation": "Validation Error"
        },
        "title": "New User"
      },
      "resend": {
        "server": "Unable to resend at the moment",
        "success": "Password resent"
      },
      "roles": {
        "admin": "Admin",
        "user": "User"
      },
      "view": {
        "cognitoTitle": "User Authentication Status",
        "crumb": "View",
        "navTitle": "View",
        "resendPasswordButton": "Resend password",
        "title": "View User",
        "user-details": "User Details"
      }
    },
    "property": {
      "archive": {
        "body": "<p>This will archive the Property “<b>{name}</b>”.</p><p>This Property will no longer appear in the system. All data will be archived and require a manual process to restore.</p>",
        "button": "Archive Property",
        "crumb": "Archive",
        "messages": {
          "server": "Server Error",
          "success": "Property Archived"
        },
        "navTitle": "Archive",
        "title": "Archive Property"
      },
      "details": {
        "addressCity": {
          "label": "Address City",
          "tip": "This is a tip for Addresscity"
        },
        "addressCountry": {
          "label": "Address Country",
          "tip": "This is a tip for Addresscountry"
        },
        "addressCounty": {
          "label": "Address County",
          "tip": "This is a tip for Addresscounty"
        },
        "addressLine1": {
          "label": "Address Line1",
          "tip": "This is a tip for Addressline1"
        },
        "addressPostcode": {
          "label": "Address Postcode",
          "tip": "This is a tip for Addresspostcode"
        },
        "createdAt": {
          "label": "Created at",
          "tip": "This is a tip for Createdat"
        },
        "createdBy": {
          "label": "Created by",
          "tip": "This is a tip for Createdby"
        },
        "createdById": {
          "label": "Created by Id",
          "tip": "This is a tip for Createdbyid"
        },
        "latitude": {
          "label": "Latitude",
          "tip": "This is a tip for Latitude"
        },
        "notes": {
          "label": "Notes",
          "tip": "This is a tip for Notes"
        },
        "partner": {
          "label": "Group",
          "tip": "This is a tip for Group"
        },
        "propertyCode": {
          "label": "Property Code",
          "tip": "This is a tip for Propertycode"
        },
        "updatedAt": {
          "label": "Updated at",
          "tip": "This is a tip for Updatedat"
        },
        "updatedBy": {
          "label": "Updated by",
          "tip": "This is a tip for Updatedby"
        },
        "updatedById": {
          "label": "Updated by Id",
          "tip": "This is a tip for Updatedbyid"
        }
      },
      "edit": {
        "crumb": "Edit",
        "messages": {
          "cancel": "Changes Reset",
          "server": "Server Error",
          "success": "Property Updated",
          "validation": "Validation Error"
        },
        "navTitle": "Edit",
        "title": "Edit Property"
      },
      "filter": {
        "clear": "Clear Filter",
        "submit": "Update Filter"
      },
      "form": {
        "addressCity": {
          "label": "Address City",
          "placeholder": "Enter Addresscity",
          "tip": "This is a tip for Addresscity"
        },
        "addressCountry": {
          "label": "Address Country",
          "placeholder": "Enter Addresscountry",
          "tip": "This is a tip for Addresscountry"
        },
        "addressCounty": {
          "label": "Address County",
          "placeholder": "Enter Addresscounty",
          "tip": "This is a tip for Addresscounty"
        },
        "addressLine1": {
          "label": "Address Line1",
          "placeholder": "Enter Addressline1",
          "tip": "This is a tip for Addressline1"
        },
        "addressPostcode": {
          "label": "Address Postcode",
          "placeholder": "Enter Addresspostcode",
          "tip": "This is a tip for Addresspostcode"
        },
        "createdAt": {
          "label": "Created at",
          "placeholder": "Enter Createdat",
          "tip": "This is a tip for Createdat"
        },
        "createdBy": {
          "label": "Created by",
          "placeholder": "Enter Createdby",
          "tip": "This is a tip for Createdby"
        },
        "createdById": {
          "label": "Created by Id",
          "placeholder": "Enter Createdbyid",
          "tip": "This is a tip for Createdbyid"
        },
        "latitude": {
          "label": "Latitude",
          "placeholder": "Enter Latitude",
          "tip": "This is a tip for Latitude"
        },
        "notes": {
          "label": "Notes",
          "placeholder": "Enter Notes",
          "tip": "This is a tip for Notes"
        },
        "partner": {
          "label": "Group",
          "placeholder": "Enter Group",
          "tip": "This is a tip for Group"
        },
        "propertyCode": {
          "label": "Property Code",
          "placeholder": "Enter Propertycode",
          "tip": "This is a tip for Propertycode"
        },
        "updatedAt": {
          "label": "Updated at",
          "placeholder": "Enter Updatedat",
          "tip": "This is a tip for Updatedat"
        },
        "updatedBy": {
          "label": "Updated by",
          "placeholder": "Enter Updatedby",
          "tip": "This is a tip for Updatedby"
        },
        "updatedById": {
          "label": "Updated by Id",
          "placeholder": "Enter Updatedbyid",
          "tip": "This is a tip for Updatedbyid"
        }
      },
      "list": {
        "actions": {
          "export": {
            "button": "Download",
            "label": "Export",
            "text": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius aliquam laudantium explicabo pariatur iste dolorem animi vitae error totam. At sapiente aliquam accusamus facere veritatis.",
            "title": "Export Properties"
          },
          "filter": "Filter",
          "new": "Add New Property",
          "search": "Search",
          "sort": {
            "label": "Sort",
            "options": {
              "createdAt": "Created",
              "id": "ID",
              "updatedAt": "Updated"
            }
          }
        },
        "crumb": "Properties",
        "messages": {
          "export": "Export Started"
        },
        "title": "List Properties"
      },
      "nav": {
        "icon": "nav.users",
        "label": "Properties"
      },
      "new": {
        "crumb": "New",
        "messages": {
          "server": "Server Error",
          "success": "Property Created",
          "validation": "Validation Error"
        },
        "title": "New Property"
      },
      "view": {
        "crumb": "View",
        "navTitle": "View",
        "title": "View Property"
      }
    },
    "search": {
      "placeholder": "Search"
    },
    "session-partner": {
      "form": {
        "criticalAlertSub": {
          "label": "Critical",
          "tip": "Subscribe to critical alert emails"
        },
        "emailsEnabled": {
          "label": "Enabled/Disable",
          "tip": "Enable or disable emails"
        },
        "footer": {
          "cancel": "Cancel",
          "save": "Save Changes"
        },
        "infoAlertSub": {
          "label": "Info",
          "tip": "Subscribe to info alert emails"
        },
        "warningAlertSub": {
          "label": "Warning",
          "tip": "Subscribe to warning alert emails"
        }
      }
    },
    "settings": {
      "attributes": {
        "addressLine1": {
          "label": "Address",
          "placeholder": "Enter your Address",
          "tip": "Your Address"
        },
        "crumb": "Change Name",
        "family_name": {
          "label": "Last Name",
          "placeholder": "Enter your Last Name",
          "tip": "Your Last Name"
        },
        "given_name": {
          "label": "First Name",
          "placeholder": "Enter your First Name",
          "tip": "Your First Name"
        },
        "navTitle": "Change Name",
        "title": "Change Name",
        "userDetailsLink": "Change Name"
      },
      "contact": {
        "countryCode": {
          "label": "Country Code",
          "placeholder": "Select your country code",
          "tip": "Your Country Code"
        },
        "crumb": "Change Contact Number",
        "messages": {
          "cancel": "Phone number details change cancelled",
          "server": "Server error",
          "success": "Phone number details successfully changed",
          "validation": "Validation Error"
        },
        "navTitle": "Change Contact Number",
        "phoneNumber": {
          "label": "Phone Number",
          "placeholder": "Enter your Phone Number",
          "tip": "Your Phone Number"
        },
        "title": "Change Contact Number",
        "userDetailsLink": "Change Contact Number"
      },
      "crumb": "Settings",
      "emailPreferences": {
        "crumb": "Email Preferences",
        "emailPreferencesLink": "Email Preferences",
        "messages": {
          "cancel": "Settings changes cancelled",
          "server": "Server error",
          "success": "Settings successfully changed"
        },
        "navTitle": "Email Preferences",
        "title": "Email Preferences for {name}"
      },
      "flags": {
        "debug": "Debug",
        "header": "User Flags"
      },
      "group": {
        "navTitle": "Group"
      },
      "header": "Preferences",
      "navTitle": "User Flags",
      "password": {
        "changePasswordLink": "Change Password",
        "crumb": "Change Password",
        "messages": {
          "cancel": "Password change cancelled",
          "server": "Server error",
          "success": "Password successfully changed"
        },
        "navTitle": "Change Password",
        "oldPassword": {
          "label": "Current Password",
          "placeholder": "Password",
          "tip": "Your existing password to the portal"
        },
        "password": {
          "label": "New Password",
          "placeholder": "New Password",
          "tip": "Passwords must be at least 8 characters long, must contain at least one lowercase character, uppercase character, special character and number"
        },
        "passwordConfirmation": {
          "label": "Confirm Password",
          "placeholder": "Confirm Password",
          "tip": "Confirmation of the new password"
        },
        "submit": "Save",
        "title": "Change Password"
      },
      "title": "User Flags",
      "userDetails": {
        "messages": {
          "cancel": "User detail changes cancelled",
          "server": "Server error",
          "success": "User details successfully changed",
          "validation": "Validation Error"
        }
      }
    },
    "user": {
      "customerService": "Customer Service"
    },
    "webhook": {
      "archive": {
        "body": "<p>This will remove the Webhook “<b>{name}</b>”.</p><p>The webhook may still be called for a short period as current event processing completes</p>",
        "button": "Archive Webhook",
        "crumb": "Archive",
        "messages": {
          "cancel": "Changes Reset",
          "server": "Server Error",
          "success": "Webhook Archived"
        },
        "navTitle": "Archive",
        "title": "Archive Webhook"
      },
      "audit": {
        "from": {
          "label": "Start Date :"
        },
        "to": {
          "label": "End Date :"
        }
      },
      "auditLog": {
        "body": "<p>Export audit logs for webhook “<b>{name}</b>”.</p><p>Please select a start and end date and click on the download button to download a CSV file contains a list of audit logs for that periods</p>",
        "button": "Download",
        "crumb": "Audit Log",
        "messages": {
          "cancel": "Changes Reset",
          "export": "Export Started",
          "noResults": "No results, try a different date/time range",
          "partialResults": "Not all audit logs were returned, please try downloading again",
          "server": "Server Error",
          "success": "Webhook Archived"
        },
        "navTitle": "Audit Log",
        "title": "Audit Log",
        "titleShort": "Export Audit Logs"
      },
      "confirm": {
        "body": "Webhooks are an advanced feature that can be used to integrate with third party systems. They should not be created or modified without consideration for the impact they may have on the other systems.",
        "heading": "Confirm",
        "title": "Read before continuing"
      },
      "details": {
        "enabled": {
          "label": "Enabled",
          "tip": "The status of the webhook"
        },
        "eventTypes": {
          "label": "Event Types",
          "tip": "The type of events the webhook is subscribing to"
        },
        "name": {
          "label": "Name",
          "tip": "The name of the webhook"
        },
        "url": {
          "label": "URL",
          "tip": "The url of the webhook"
        }
      },
      "edit": {
        "crumb": "Edit",
        "messages": {
          "cancel": "Changes Reset",
          "server": "Server Error",
          "success": "Webhook Updated",
          "validation": "Validation Error"
        },
        "navTitle": "Edit",
        "title": "Edit Webhook"
      },
      "filter": {
        "clear": "Clear Filter",
        "enabled": {
          "label": "Enabled",
          "placeholder": "Either",
          "tip": "Show only enabled or disabled webhooks"
        },
        "eventTypes": {
          "label": "Event type",
          "placeholder": "All types",
          "tip": "Show webhooks with specific event a type"
        },
        "submit": "Update Filter"
      },
      "form": {
        "enabled": {
          "label": "Enabled",
          "tip": "The status of the webhook"
        },
        "eventTypes": {
          "label": "Event Types",
          "placeholder": "Enter Webhook Event Types",
          "tip": "The type of events the webhook is subscribing to"
        },
        "name": {
          "label": "Name",
          "placeholder": "Enter Webhook Name",
          "tip": "The name of the webhook"
        },
        "url": {
          "label": "URL",
          "placeholder": "Enter Webhook Url",
          "tip": "The url of the webhook"
        }
      },
      "list": {
        "actions": {
          "filter": {
            "label": "Filter"
          },
          "new": "New Webhook",
          "sort": {
            "label": "Sort",
            "options": {
              "name": "Name",
              "updatedAt": "Updated At"
            }
          }
        },
        "crumb": "Webhooks",
        "item": {
          "updatedAt": {
            "label": "Last Updated At"
          },
          "updatedBy": {
            "label": "Updated By"
          },
          "url": {
            "label": "Url"
          }
        },
        "title": "Webhooks"
      },
      "nav": {
        "icon": "nav.users",
        "label": "Webhooks"
      },
      "new": {
        "crumb": "New",
        "messages": {
          "cancel": "Changes Reset",
          "server": "Server Error",
          "success": "Webhook Created",
          "validation": "Validation Error"
        },
        "title": "New Webhook"
      },
      "view": {
        "crumb": "View",
        "navTitle": "View",
        "title": "View Webhook"
      }
    }
  }], ["en-us", {
    "device": {
      "gas-consumption": {
        "boilerModel": {
          "label": "Boiler Model",
          "tip": "The model of the boiler"
        },
        "dayPowerUseage": {
          "label": "24 Hour Total (KWH)",
          "tip": "The amount of power used in 24 hours"
        },
        "detail": {
          "body": "<p>To see how we calculate the total gas consumption used for devices, and validate the estimated values produced by the equation email us at enquires@idealheating.com</p>",
          "title": "Details"
        },
        "detail-summary": {
          "title": "Summary"
        },
        "header": "Estimated Gas Consumption Calculation",
        "maxChFanSpeed": {
          "label": "Max CH Fan Speed",
          "tip": "The maximum central heating fan speed"
        },
        "maxDhwFanSpeed": {
          "label": "Max DHW Fan Speed",
          "tip": "The maximum domestic hot water fan speed"
        },
        "minFanSpeed": {
          "label": "Min Fan Speed",
          "tip": "The minimum fan speed"
        },
        "monthPowerUseage": {
          "label": "Month Total (KWH)",
          "tip": "The amount of power used in a month"
        },
        "table": {
          "header": {
            "date": "Date",
            "fanAggregate": "Fan Aggregate (Ch)",
            "flameOnDuration": "Flame on Duration (Ch)",
            "gasConsumption": "Calculated Gas Consumption (kWh)",
            "time": "Time"
          }
        },
        "weekPowerUseage": {
          "label": "Week Total (KWH)",
          "tip": "The amount of power used in a week"
        },
        "yearPowerUseage": {
          "label": "Year Total (KWH)",
          "tip": "The amount of power used in a year"
        }
      }
    },
    "footer": {
      "version": "Version"
    },
    "home": {
      "title": "Welcome to the Ideal Heating portal"
    },
    "languages": {
      "en-uk": "English (UK)",
      "en-us": "American English (US)"
    }
  }]];
});